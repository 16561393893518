$doc-ilu-size: 100px;
$doc-ilu-small-size:75px;

$docs-ilu-shadow: 0 1px 2px rgba(0,0,0,0.025), 
0 4px 4px rgba(0,0,0,0.025), 
0 8px 8px rgba(0,0,0,0.025), 
0 12px 16px rgba(0,0,0,0.025),
0 20px 32px rgba(0,0,0,0.025), 
0 36px 64px rgba(0,0,0,0.025);

.docs {
    &__list {
        list-style: none;
        margin: $spacing-medium 0;
        padding: 0;

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    &__section-title {
        font-weight: 300;
        font-size: $font-size-small * 2;
        font-family: $font-family-condensed;
        color: $grey-color;
        margin: $spacing-big 0 $spacing-medium 0;
    }

    &__item {
        flex-basis: auto;
        display: flex;
        text-align: left;
        align-items: flex-start;

        @include media-breakpoint-up(sm) {
            flex-basis: 31%;
            margin: 0;
        }

        @include media-breakpoint-up(md) {
            &:last-child:nth-child(3n - 2) {
                grid-column-end: 3;
            }
            margin-bottom: $spacing-default;
            padding-right: $spacing-big;
        }

        @include media-breakpoint-up(lg) {
        }
    }

    &__ilu {
        box-shadow: $docs-ilu-shadow;
        padding: $spacing-default;
        width: $doc-ilu-size;
        min-height: auto;
        margin: 0 $spacing-default 0 0;

        @include media-breakpoint-up(sm) {
            width: $doc-ilu-small-size;
        }
    }

    &__title {
        display: block;
        margin-top: $spacing-small;
        color: $link-color;
        text-decoration: none;

        .docs__desc {
            display: block;
            font-size: $font-size-small;
            color: $light-grey-color;
            margin-top: $spacing-tiny;
        }
    }
}