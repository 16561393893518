
$red-color: #c51e2e;
$green-color: #009a3e;
$blue-color: #1c78f3;
$black-color: #222;
$grey-color: #555;
$light-grey-color: #999;
$platine-color: #ddd;

$brand-color: $green-color;
$link-color: $blue-color;
$text-color: $black-color;

